const tasksEnumerators = {
  status: [
    'open',
    'in_progress',
    'stuck',
    'closed',
  ],
}

export default tasksEnumerators
