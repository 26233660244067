import CourseCommentService from '../../../modules/courseComment/courseCommentService'
import Errors from '../../../modules/shared/error/errors'
import Message from '../../../view/shared/message'
import { push } from 'redux-first-history'
import { i18n } from '../../../i18n'

const prefix = 'COURSECOMMENT_FORM'

const courseCommentFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: courseCommentFormActions.INIT_STARTED,
      })

      let record = {}

      const isEdit = Boolean(id)

      if (isEdit) {
        record = await CourseCommentService.find(id)
      }

      dispatch({
        type: courseCommentFormActions.INIT_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: courseCommentFormActions.INIT_ERROR,
      })

      dispatch(push('/course-comment'))
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: courseCommentFormActions.CREATE_STARTED,
      })

      await CourseCommentService.create(values)

      dispatch({
        type: courseCommentFormActions.CREATE_SUCCESS,
      })

      Message.success(
        i18n('entities.courseComment.create.success'),
      )

      dispatch(push('/course-comment'))
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: courseCommentFormActions.CREATE_ERROR,
      })
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: courseCommentFormActions.UPDATE_STARTED,
      })

      await CourseCommentService.update(id, values)

      dispatch({
        type: courseCommentFormActions.UPDATE_SUCCESS,
      })

      Message.success(
        i18n('entities.courseComment.update.success'),
      )

      dispatch(push('/course-comment'))
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: courseCommentFormActions.UPDATE_ERROR,
      })
    }
  },
}

export default courseCommentFormActions
