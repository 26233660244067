import authSelectors from '../../../modules/auth/authSelectors'
import layoutSelectors from '../../../modules/layout/layoutSelectors'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import EmailUnverifiedRoute from './EmailUnverifiedRoute'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import SimpleRoute from './SimpleRoute'
import CustomLoadable from '../CustomLoadable'
import ProgressBar from '../ProgressBar'
import routes from '../../routes'
import EmptyTenantRoute from './EmptyTenantRoute'
import EmptyPermissionsRoute from './EmptyPermissionsRoute'

function RoutesComponent () {
  const isInitialMount = useRef(true)

  const authLoading = useSelector(
    authSelectors.selectLoadingInit,
  )
  const layoutLoading = useSelector(
    layoutSelectors.selectLoading,
  )
  const loading = authLoading || layoutLoading
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  )
  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  )

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      ProgressBar.start()
      return
    }

    if (!loading) {
      ProgressBar.done()
    }
  }, [loading])

  if (loading) {
    return <div />
  }

  return (
    <Routes>
      {routes.publicRoutes.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
                <PublicRoute
                  component={CustomLoadable({
                    loader: route.loader,
                  })}
                  currentUser={currentUser}
                  currentTenant={currentTenant}
                />
            }
          />
        )
      })}
      {routes.emailUnverifiedRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <EmailUnverifiedRoute
              component={CustomLoadable({
                loader: route.loader,
              })}
              currentUser={currentUser}
              currentTenant={currentTenant}
            />
          }
        />
      ))}

      {routes.emptyTenantRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <EmptyTenantRoute
              component={CustomLoadable({
                loader: route.loader,
              })}
              currentUser={currentUser}
              currentTenant={currentTenant}
            />
          }
        />
      ))}

      {routes.emptyPermissionsRoutes.map((route) => (
         <Route
          key={route.path}
          path={route.path}
          element={
            <EmptyPermissionsRoute
              component={CustomLoadable({
                loader: route.loader,
              })}
              currentUser={currentUser}
              currentTenant={currentTenant}
            />
          }
        />
      ))}

      {routes.privateRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <PrivateRoute
              component={CustomLoadable({
                loader: route.loader,
              })}
              currentUser={currentUser}
              currentTenant={currentTenant}
              permissionRequired={route.permissionRequired}
            />
          }
        />
      ))}

      {routes.simpleRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <SimpleRoute
              component={CustomLoadable({
                loader: route.loader,
              })}
            />
          }
        />
      ))}
    </Routes>
  )
}

export default RoutesComponent
