import importerActions from '../../../modules/shared/importer/importerActions'
import selectors from '../../../modules/subcategory/importer/subcategoryImporterSelectors'
import CategoryService from '../../../modules/subcategory/subcategoryService'
import fields from '../../../modules/subcategory/importer/subcategoryImporterFields'
import { i18n } from '../../../i18n'

const subcategoryImporterActions = importerActions(
  'SUBCATEGORY_IMPORTER',
  selectors,
  CategoryService.import,
  fields,
  i18n('entities.subcategory.importer.fileName'),
)

export default subcategoryImporterActions
