let inMemoryToken = null

export class AuthToken {
  static get () {
    return (
      inMemoryToken || localStorage.getItem('jwt') || null
    )
  }

  static set (token, rememberMe) {
    try {
      if (rememberMe) {
        localStorage.setItem('jwt', token || '')
      } else {
        inMemoryToken = token
        localStorage.setItem('jwt', '')
      }
    } catch (error) {
      console.error('error: ', error)
    }
  }

  static applyFromLocationUrlIfExists () {
    try {
      const urlParams = new URLSearchParams(
        window.location.search,
      )
      const authToken = urlParams.get('authToken')

      if (!authToken) {
        return
      }

      this.set(authToken, true)
      window.history.replaceState(
        {},
        document.title,
        window.location.origin,
      )
    } catch (error) {
      console.error('error: ', error)
    }
  }
}
