import React from 'react'
import Header from '../../view/layout/Header'
import Menu from '../../view/layout/Menu'
import layoutSelectors from '../../modules/layout/layoutSelectors'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

function Layout (props) {
  const location = useLocation()
  const pathname = location.pathname
  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  )

  return (
    <div className="flex h-full dark:bg-gray-600">
      <Menu url={pathname || ''} />
      <div
        className={`${
          menuVisible ? 'hidden' : 'flex'
        } sm:flex md:flex lg:flex bg-gray-100 dark:bg-gray-600 flex-col flex-auto min-h-screen overflow-x-hidden`}
      >
        <Header />
        <div className="p-6">{props.children}</div>
      </div>
    </div>
  )
}

export default Layout
