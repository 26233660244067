import PermissionChecker from '../../../modules/auth/permissionChecker'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function EmptyPermissionsRoute ({
  component: Component,
  currentTenant,
  currentUser,
}) {
  const permissionChecker = new PermissionChecker(
    currentTenant,
    currentUser,
  )

  if (!permissionChecker.isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: '/auth/signin',
        }}
      />
    )
  }

  if (!permissionChecker.isEmptyPermissions) {
    return <Navigate to="/" />
  }

  return (
    <>
      <Component />
      <Outlet />
    </>
  )
}

export default EmptyPermissionsRoute
