import PostsService from '../../../modules/posts/postsService'
import selectors from '../../../modules/posts/list/postsListSelectors'
import { i18n } from '../../../i18n'
import exporterFields from '../../../modules/posts/list/postsListExporterFields'
import Errors from '../../../modules/shared/error/errors'
import Exporter from '../../../modules/shared/exporter/exporter'

const prefix = 'POSTS_LIST'

const postsListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doClearAllSelected () {
    return {
      type: postsListActions.CLEAR_ALL_SELECTED,
    }
  },

  doToggleAllSelected () {
    return {
      type: postsListActions.TOGGLE_ALL_SELECTED,
    }
  },

  doToggleOneSelected (id) {
    return {
      type: postsListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    }
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: postsListActions.RESETED,
    })

    dispatch(postsListActions.doFetch())
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || (exporterFields.length === 0)) {
        throw new Error('exporterFields is required')
      }

      dispatch({
        type: postsListActions.EXPORT_STARTED,
      })

      const filter = selectors.selectFilter(getState())
      const response = await PostsService.list(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      )

      new Exporter(
        exporterFields,
        i18n('entities.posts.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows)

      dispatch({
        type: postsListActions.EXPORT_SUCCESS,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: postsListActions.EXPORT_ERROR,
      })
    }
  },

  doChangePagination:
    (pagination) => async (dispatch, getState) => {
      dispatch({
        type: postsListActions.PAGINATION_CHANGED,
        payload: pagination,
      })

      dispatch(postsListActions.doFetchCurrentFilter())
    },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: postsListActions.SORTER_CHANGED,
      payload: sorter,
    })

    dispatch(postsListActions.doFetchCurrentFilter())
  },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState())
      const rawFilter = selectors.selectRawFilter(
        getState(),
      )
      dispatch(
        postsListActions.doFetch(filter, rawFilter, true),
      )
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: postsListActions.FETCH_STARTED,
            payload: { filter, rawFilter, keepPagination },
          })

          const response = await PostsService.list(
            filter,
            selectors.selectOrderBy(getState()),
            selectors.selectLimit(getState()),
            selectors.selectOffset(getState()),
          )

          dispatch({
            type: postsListActions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          })
        } catch (error) {
          Errors.handle(error)

          dispatch({
            type: postsListActions.FETCH_ERROR,
          })
        }
      },
}

export default postsListActions
