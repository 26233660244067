import authAxios from '../../modules/shared/axios/authAxios'
import AuthCurrentTenant from '../../modules/auth/authCurrentTenant'

export default class TaskListService {
  static async update (id, data) {
    const body = {
      id,
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(
      `/tenant/${tenantId}/task-list/${id}`,
      body,
    )

    return response.data
  }

  static async destroyAll (ids) {
    const params = {
      ids,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(
      `/tenant/${tenantId}/task-list`,
      {
        params,
      },
    )

    return response.data
  }

  static async create (data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(
      `/tenant/${tenantId}/task-list`,
      body,
    )

    return response.data
  }

  static async import (values, importHash) {
    const body = {
      data: values,
      importHash,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(
      `/tenant/${tenantId}/task-list/import`,
      body,
    )

    return response.data
  }

  static async find (id) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/task-list/${id}`,
    )

    return response.data
  }

  static async list (filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/task-list`,
      {
        params,
      },
    )

    return response.data
  }

  static async listAutocomplete (query, limit) {
    const params = {
      query,
      limit,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/task-list/autocomplete`,
      {
        params,
      },
    )

    return response.data
  }
}
