import Permissions from '../security/permissions'
import config from '../config'
import {
  faChevronRight,
  faCog,
  faCreditCard,
  faHistory,
  faThLarge,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons'

const permissions = Permissions.values

export default [
  {
    path: '/',
    exact: true,
    icon: faThLarge,
    label: 'dashboard.menu',
    permissionRequired: null,
  },

  config.isPlanEnabled && {
    path: '/plan',
    permissionRequired: permissions.planRead,
    icon: faCreditCard,
    label: 'plan.menu',
  },

  {
    path: '/user',
    label: 'user.menu',
    permissionRequired: permissions.userRead,
    icon: faUserPlus,
  },

  {
    path: '/audit-logs',
    icon: faHistory,
    label: 'auditLog.menu',
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    icon: faCog,
    label: 'settings.menu',
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/customer',
    permissionRequired: permissions.customerRead,
    icon: faChevronRight,
    label: 'entities.customer.menu',
  },

  {
    path: '/product',
    permissionRequired: permissions.productRead,
    icon: faChevronRight,
    label: 'entities.product.menu',
  },

  {
    path: '/order',
    permissionRequired: permissions.orderRead,
    icon: faChevronRight,
    label: 'entities.order.menu',
  },

  {
    path: '/operation',
    permissionRequired: permissions.operationRead,
    icon: faChevronRight,
    label: 'entities.operation.menu',
  },

  {
    path: '/bank',
    permissionRequired: permissions.bankRead,
    icon: faChevronRight,
    label: 'entities.bank.menu',
  },

  {
    path: '/category',
    permissionRequired: permissions.categoryRead,
    icon: faChevronRight,
    label: 'entities.category.menu',
  },

  {
    path: '/subcategory',
    permissionRequired: permissions.subcategoryRead,
    icon: faChevronRight,
    label: 'entities.subcategory.menu',
  },

  {
    path: '/task-list',
    permissionRequired: permissions.taskListRead,
    icon: faChevronRight,
    label: 'entities.taskList.menu',
  },

  {
    path: '/tasks',
    permissionRequired: permissions.tasksRead,
    icon: faChevronRight,
    label: 'entities.tasks.menu',
  },

  {
    path: '/tags',
    permissionRequired: permissions.tagsRead,
    icon: faChevronRight,
    label: 'entities.tags.menu',
  },

  {
    path: '/task-tags',
    permissionRequired: permissions.taskTagsRead,
    icon: faChevronRight,
    label: 'entities.taskTags.menu',
  },

  {
    path: '/sub-tasks',
    permissionRequired: permissions.subTasksRead,
    icon: faChevronRight,
    label: 'entities.subTasks.menu',
  },

  {
    path: '/media',
    permissionRequired: permissions.mediaRead,
    icon: faChevronRight,
    label: 'entities.media.menu',
  },

  {
    path: '/posts',
    permissionRequired: permissions.postsRead,
    icon: faChevronRight,
    label: 'entities.posts.menu',
  },

  {
    path: '/followers',
    permissionRequired: permissions.followersRead,
    icon: faChevronRight,
    label: 'entities.followers.menu',
  },

  {
    path: '/like',
    permissionRequired: permissions.likeRead,
    icon: faChevronRight,
    label: 'entities.like.menu',
  },

  {
    path: '/hashtag',
    permissionRequired: permissions.hashtagRead,
    icon: faChevronRight,
    label: 'entities.hashtag.menu',
  },

  {
    path: '/post-hashtag',
    permissionRequired: permissions.postHashtagRead,
    icon: faChevronRight,
    label: 'entities.postHashtag.menu',
  },

  {
    path: '/artist',
    permissionRequired: permissions.artistRead,
    icon: faChevronRight,
    label: 'entities.artist.menu',
  },

  {
    path: '/album',
    permissionRequired: permissions.albumRead,
    icon: faChevronRight,
    label: 'entities.album.menu',
  },

  {
    path: '/song',
    permissionRequired: permissions.songRead,
    icon: faChevronRight,
    label: 'entities.song.menu',
  },

  {
    path: '/playlist',
    permissionRequired: permissions.playlistRead,
    icon: faChevronRight,
    label: 'entities.playlist.menu',
  },

  {
    path: '/playlist-song',
    permissionRequired: permissions.playlistSongRead,
    icon: faChevronRight,
    label: 'entities.playlistSong.menu',
  },

  {
    path: '/user-playlist',
    permissionRequired: permissions.userPlaylistRead,
    icon: faChevronRight,
    label: 'entities.userPlaylist.menu',
  },

  {
    path: '/city',
    permissionRequired: permissions.cityRead,
    icon: faChevronRight,
    label: 'entities.city.menu',
  },

  {
    path: '/country',
    permissionRequired: permissions.countryRead,
    icon: faChevronRight,
    label: 'entities.country.menu',
  },

  {
    path: '/address',
    permissionRequired: permissions.addressRead,
    icon: faChevronRight,
    label: 'entities.address.menu',
  },

  {
    path: '/product-category',
    permissionRequired: permissions.productCategoryRead,
    icon: faChevronRight,
    label: 'entities.productCategory.menu',
  },

  {
    path: '/order-detail',
    permissionRequired: permissions.orderDetailRead,
    icon: faChevronRight,
    label: 'entities.orderDetail.menu',
  },

  {
    path: '/payment-methods',
    permissionRequired: permissions.paymentMethodsRead,
    icon: faChevronRight,
    label: 'entities.paymentMethods.menu',
  },

  {
    path: '/product-review',
    permissionRequired: permissions.productReviewRead,
    icon: faChevronRight,
    label: 'entities.productReview.menu',
  },

  {
    path: '/promotions',
    permissionRequired: permissions.promotionsRead,
    icon: faChevronRight,
    label: 'entities.promotions.menu',
  },

  {
    path: '/course',
    permissionRequired: permissions.courseRead,
    icon: faChevronRight,
    label: 'entities.course.menu',
  },

  {
    path: '/teacher',
    permissionRequired: permissions.teacherRead,
    icon: faChevronRight,
    label: 'entities.teacher.menu',
  },

  {
    path: '/lesson',
    permissionRequired: permissions.lessonRead,
    icon: faChevronRight,
    label: 'entities.lesson.menu',
  },

  {
    path: '/course-enrollment',
    permissionRequired: permissions.courseEnrollmentRead,
    icon: faChevronRight,
    label: 'entities.courseEnrollment.menu',
  },

  {
    path: '/student',
    permissionRequired: permissions.studentRead,
    icon: faChevronRight,
    label: 'entities.student.menu',
  },

  {
    path: '/course-comment',
    permissionRequired: permissions.courseCommentRead,
    icon: faChevronRight,
    label: 'entities.courseComment.menu',
  },

  {
    path: '/course-transaction',
    permissionRequired: permissions.courseTransactionRead,
    icon: faChevronRight,
    label: 'entities.courseTransaction.menu',
  },

  {
    path: '/course-category',
    permissionRequired: permissions.courseCategoryRead,
    icon: faChevronRight,
    label: 'entities.courseCategory.menu',
  },

  {
    path: '/category-course-relation',
    permissionRequired:
      permissions.categoryCourseRelationRead,
    icon: faChevronRight,
    label: 'entities.categoryCourseRelation.menu',
  },

  {
    path: '/course-progress',
    permissionRequired: permissions.courseProgressRead,
    icon: faChevronRight,
    label: 'entities.courseProgress.menu',
  },

  {
    path: '/message',
    permissionRequired: permissions.messageRead,
    icon: faChevronRight,
    label: 'entities.message.menu',
  },

  {
    path: '/conversation',
    permissionRequired: permissions.conversationRead,
    icon: faChevronRight,
    label: 'entities.conversation.menu',
  },

  {
    path: '/conversation-participant',
    permissionRequired:
      permissions.conversationParticipantRead,
    icon: faChevronRight,
    label: 'entities.conversationParticipant.menu',
  },

  {
    path: '/business',
    permissionRequired: permissions.businessRead,
    icon: faChevronRight,
    label: 'entities.business.menu',
  },

  {
    path: '/business-category',
    permissionRequired: permissions.businessCategoryRead,
    icon: faChevronRight,
    label: 'entities.businessCategory.menu',
  },

  {
    path: '/service',
    permissionRequired: permissions.serviceRead,
    icon: faChevronRight,
    label: 'entities.service.menu',
  },

  {
    path: '/availability-timeslot',
    permissionRequired:
      permissions.availabilityTimeslotRead,
    icon: faChevronRight,
    label: 'entities.availabilityTimeslot.menu',
  },

  {
    path: '/appointment',
    permissionRequired: permissions.appointmentRead,
    icon: faChevronRight,
    label: 'entities.appointment.menu',
  },

  {
    path: '/brand',
    permissionRequired: permissions.brandRead,
    icon: faChevronRight,
    label: 'entities.brand.menu',
  },

  {
    path: '/delivery-method',
    permissionRequired: permissions.deliveryMethodRead,
    icon: faChevronRight,
    label: 'entities.deliveryMethod.menu',
  },
].filter(Boolean)
