import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { i18n, init as i18nInit } from './i18n'
import { AuthToken } from './modules/auth/authToken'
import TenantService from './modules/tenant/tenantService'
import SettingsService from './modules/settings/settingsService'
import AuthService from './modules/auth/authService'
import App from './App'
import 'react-tooltip/dist/react-tooltip.css'
import './index.css'

const AppContainer = () => {
  const [isInitialized, setIsInitialized] = useState(false)
  useEffect(() => {
    (async function () {
      try {
        const isSocialOnboardRequested = AuthService.isSocialOnboardRequested()
        AuthToken.applyFromLocationUrlIfExists()
        await TenantService.fetchAndApply()
        if (isSocialOnboardRequested) {
          await AuthService.socialOnboard()
        }
        SettingsService.applyThemeFromTenant()
        await i18nInit()
        document.title = i18n('app.title')
        setIsInitialized(true)
      } catch (error) {
        console.error('error: ', error)
      }
    })()
  }, [])

  return isInitialized ? <App /> : null
}

ReactDOM.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
  document.getElementById('root')
)
