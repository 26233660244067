import React from 'react'
import PermissionChecker from '../../../modules/auth/permissionChecker'
import { Navigate, Outlet } from 'react-router-dom'

function PublicRoute ({
  component: Component,
  currentTenant,
  currentUser,
}) {
  const permissionChecker = new PermissionChecker(
    currentTenant,
    currentUser,
  )

  if (permissionChecker.isAuthenticated) {
    return <Navigate to="/" />
  }

  return (
    <>
      <Component />
      <Outlet />
    </>
  )
}

export default PublicRoute
