import React from 'react'
import { configStore, getHistory } from './modules/store'
import { Provider, useSelector } from 'react-redux'
import layoutSelectors from './modules/layout/layoutSelectors'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import RoutesComponent from './view/shared/routes/RoutesComponent'

const AppInnerComponent = () => {
  const isDarkMode = useSelector(layoutSelectors.selectDarkMode)
  return (
    <div className={`${isDarkMode ? 'dark' : ''}`}>
      <RoutesComponent />
    </div>
  )
}

const App = () => {
  return (
    <Provider store={configStore()}>
      <Router history={getHistory()}>
        <AppInnerComponent />
      </Router>
    </Provider>
  )
}

export default App
