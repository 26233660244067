import FollowersService from '../../../modules/followers/followersService'
import Errors from '../../../modules/shared/error/errors'
import { push } from 'redux-first-history'

const prefix = 'FOLLOWERS_VIEW'

const followersViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: followersViewActions.FIND_STARTED,
      })

      const record = await FollowersService.find(id)

      dispatch({
        type: followersViewActions.FIND_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: followersViewActions.FIND_ERROR,
      })

      dispatch(push('/followers'))
    }
  },
}

export default followersViewActions
