import PermissionChecker from '../../../modules/auth/permissionChecker'
import React from 'react'
import {
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom'
import Layout from '../../../view/layout/Layout'
import config from '../../../config'
import { tenantSubdomain } from '../../../modules/tenant/tenantSubdomain'

function PrivateRoute ({
  component: Component,
  currentTenant,
  currentUser,
  permissionRequired,
}) {
  const location = useLocation()
  const permissionChecker = new PermissionChecker(
    currentTenant,
    currentUser,
  )

  if (!permissionChecker.isAuthenticated) {
    return (
      <Navigate
        to='/auth/signin'
        state={{ from: location }}
      />
    )
  }

  if (!permissionChecker.isEmailVerified) {
    return <Navigate to="/auth/email-unverified" />
  }

  if (
    ['multi', 'multi-with-subdomain'].includes(
      config.tenantMode,
    ) &&
      !tenantSubdomain.isSubdomain
  ) {
    if (permissionChecker.isEmptyTenant) {
      return <Navigate to="/auth/tenant" />
    }
  } else {
    if (permissionChecker.isEmptyPermissions) {
      return (
          <Navigate to="/auth/empty-permissions" />
      )
    }
  }

  if (!permissionChecker.match(permissionRequired)) {
    return <Navigate to="/403" />
  }

  return (
    <>
      <Layout >
        <Component />
        <Outlet />
      </Layout>
    </>
  )
}

export default PrivateRoute
