import ProductCategoryService from '../../../modules/productCategory/productCategoryService'
import selectors from '../../../modules/productCategory/list/productCategoryListSelectors'
import { i18n } from '../../../i18n'
import exporterFields from '../../../modules/productCategory/list/productCategoryListExporterFields'
import Errors from '../../../modules/shared/error/errors'
import Exporter from '../../../modules/shared/exporter/exporter'

const prefix = 'PRODUCTCATEGORY_LIST'

const productCategoryListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doClearAllSelected () {
    return {
      type: productCategoryListActions.CLEAR_ALL_SELECTED,
    }
  },

  doToggleAllSelected () {
    return {
      type: productCategoryListActions.TOGGLE_ALL_SELECTED,
    }
  },

  doToggleOneSelected (id) {
    return {
      type: productCategoryListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    }
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: productCategoryListActions.RESETED,
    })

    dispatch(productCategoryListActions.doFetch())
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || (exporterFields.length === 0)) {
        throw new Error('exporterFields is required')
      }

      dispatch({
        type: productCategoryListActions.EXPORT_STARTED,
      })

      const filter = selectors.selectFilter(getState())
      const response = await ProductCategoryService.list(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      )

      new Exporter(
        exporterFields,
        i18n('entities.productCategory.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows)

      dispatch({
        type: productCategoryListActions.EXPORT_SUCCESS,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: productCategoryListActions.EXPORT_ERROR,
      })
    }
  },

  doChangePagination:
    (pagination) => async (dispatch, getState) => {
      dispatch({
        type: productCategoryListActions.PAGINATION_CHANGED,
        payload: pagination,
      })

      dispatch(
        productCategoryListActions.doFetchCurrentFilter(),
      )
    },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: productCategoryListActions.SORTER_CHANGED,
      payload: sorter,
    })

    dispatch(
      productCategoryListActions.doFetchCurrentFilter(),
    )
  },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState())
      const rawFilter = selectors.selectRawFilter(
        getState(),
      )
      dispatch(
        productCategoryListActions.doFetch(
          filter,
          rawFilter,
          true,
        ),
      )
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: productCategoryListActions.FETCH_STARTED,
            payload: { filter, rawFilter, keepPagination },
          })

          const response = await ProductCategoryService.list(
            filter,
            selectors.selectOrderBy(getState()),
            selectors.selectLimit(getState()),
            selectors.selectOffset(getState()),
          )

          dispatch({
            type: productCategoryListActions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          })
        } catch (error) {
          Errors.handle(error)

          dispatch({
            type: productCategoryListActions.FETCH_ERROR,
          })
        }
      },
}

export default productCategoryListActions
