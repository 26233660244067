import React from 'react'
import { Outlet } from 'react-router-dom'

function PublicRoute ({
  component: Component,
}) {
  return (
    <>
      <Component />
      <Outlet />
    </>
  )
}

export default PublicRoute
