import ConversationParticipantService from '../../../modules/conversationParticipant/conversationParticipantService'
import selectors from '../../../modules/conversationParticipant/list/conversationParticipantListSelectors'
import { i18n } from '../../../i18n'
import exporterFields from '../../../modules/conversationParticipant/list/conversationParticipantListExporterFields'
import Errors from '../../../modules/shared/error/errors'
import Exporter from '../../../modules/shared/exporter/exporter'

const prefix = 'CONVERSATIONPARTICIPANT_LIST'

const conversationParticipantListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doClearAllSelected () {
    return {
      type: conversationParticipantListActions.CLEAR_ALL_SELECTED,
    }
  },

  doToggleAllSelected () {
    return {
      type: conversationParticipantListActions.TOGGLE_ALL_SELECTED,
    }
  },

  doToggleOneSelected (id) {
    return {
      type: conversationParticipantListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    }
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: conversationParticipantListActions.RESETED,
    })

    dispatch(conversationParticipantListActions.doFetch())
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || (exporterFields.length === 0)) {
        throw new Error('exporterFields is required')
      }

      dispatch({
        type: conversationParticipantListActions.EXPORT_STARTED,
      })

      const filter = selectors.selectFilter(getState())
      const response =
        await ConversationParticipantService.list(
          filter,
          selectors.selectOrderBy(getState()),
          null,
          null,
        )

      new Exporter(
        exporterFields,
        i18n(
          'entities.conversationParticipant.exporterFileName',
        ),
      ).transformAndExportAsExcelFile(response.rows)

      dispatch({
        type: conversationParticipantListActions.EXPORT_SUCCESS,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: conversationParticipantListActions.EXPORT_ERROR,
      })
    }
  },

  doChangePagination:
    (pagination) => async (dispatch, getState) => {
      dispatch({
        type: conversationParticipantListActions.PAGINATION_CHANGED,
        payload: pagination,
      })

      dispatch(
        conversationParticipantListActions.doFetchCurrentFilter(),
      )
    },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: conversationParticipantListActions.SORTER_CHANGED,
      payload: sorter,
    })

    dispatch(
      conversationParticipantListActions.doFetchCurrentFilter(),
    )
  },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState())
      const rawFilter = selectors.selectRawFilter(
        getState(),
      )
      dispatch(
        conversationParticipantListActions.doFetch(
          filter,
          rawFilter,
          true,
        ),
      )
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: conversationParticipantListActions.FETCH_STARTED,
            payload: { filter, rawFilter, keepPagination },
          })

          const response =
          await ConversationParticipantService.list(
            filter,
            selectors.selectOrderBy(getState()),
            selectors.selectLimit(getState()),
            selectors.selectOffset(getState()),
          )

          dispatch({
            type: conversationParticipantListActions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          })
        } catch (error) {
          Errors.handle(error)

          dispatch({
            type: conversationParticipantListActions.FETCH_ERROR,
          })
        }
      },
}

export default conversationParticipantListActions
