import FollowersService from '../../../modules/followers/followersService'
import Errors from '../../../modules/shared/error/errors'
import Message from '../../../view/shared/message'
import { push } from 'redux-first-history'
import { i18n } from '../../../i18n'

const prefix = 'FOLLOWERS_FORM'

const followersFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: followersFormActions.INIT_STARTED,
      })

      let record = {}

      const isEdit = Boolean(id)

      if (isEdit) {
        record = await FollowersService.find(id)
      }

      dispatch({
        type: followersFormActions.INIT_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: followersFormActions.INIT_ERROR,
      })

      dispatch(push('/followers'))
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: followersFormActions.CREATE_STARTED,
      })

      await FollowersService.create(values)

      dispatch({
        type: followersFormActions.CREATE_SUCCESS,
      })

      Message.success(
        i18n('entities.followers.create.success'),
      )

      dispatch(push('/followers'))
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: followersFormActions.CREATE_ERROR,
      })
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: followersFormActions.UPDATE_STARTED,
      })

      await FollowersService.update(id, values)

      dispatch({
        type: followersFormActions.UPDATE_SUCCESS,
      })

      Message.success(
        i18n('entities.followers.update.success'),
      )

      dispatch(push('/followers'))
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: followersFormActions.UPDATE_ERROR,
      })
    }
  },
}

export default followersFormActions
