const orderEnumerators = {
  status: [
    'requested',
    'processing',
    'delivering',
    'delivered',
    'canceled',
    'stuck',
  ],
}

export default orderEnumerators
