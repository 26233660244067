import React from 'react'
import _get from 'lodash/get'
import moment from 'moment'
import {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker'
import { setLocale as setYupLocale } from 'yup'
import { es, ptBR } from 'date-fns/locale'

let currentLanguageCode = 'en'

const languages: Record<string, {
  id: string
  label: string
  flag: string
  dateFns: any
  dictionary: any
}> = {
  en: {
    id: 'en',
    label: 'English',
    flag: '/images/flags/United-States.png',
    dateFns: null,
    dictionary: null,
  },
  es: {
    id: 'es',
    label: 'Español',
    flag: '/images/flags/Spain.png',
    dateFns: null,
    dictionary: null,
  },
  'pt-BR': {
    id: 'pt-BR',
    label: 'Português',
    flag: '/images/flags/Brazil.png',
    dateFns: null,
    dictionary: null,
  },
}

export async function init () {
  try {
    currentLanguageCode = localStorage.getItem('language') || 'es'
    setLanguageCode(currentLanguageCode)
    const language = languages[currentLanguageCode]
    let momentLocale
    language.dictionary = (await import(`./${currentLanguageCode}`)).default
    if (language.dictionary.validation) {
      setYupLocale(language.dictionary.validation)
    }
    if (currentLanguageCode === 'es') {
      momentLocale = moment.localeData('es')
      moment.locale(currentLanguageCode, momentLocale)
      language.dateFns = es
    } else if (currentLanguageCode === 'pt-BR') {
      momentLocale = moment.localeData('pt-BR')
      moment.locale(currentLanguageCode, momentLocale)
      language.dateFns = ptBR
    }
    registerLocale(currentLanguageCode, language.dateFns)
    setDefaultLocale(currentLanguageCode)
  } catch (error) {
    console.error('Error initializing i18n', error)
  }
}

export function getLanguage () {
  return languages[getLanguageCode()]
}

function format (message, args) {
  if (!message) {
    return null
  }

  try {
    return message.replace(
      /{(\d+)}/g,
      function (match, number) {
        return typeof args[number] !== 'undefined'
          ? args[number]
          : match
      },
    )
  } catch (error) {
    console.error(message, error)
    throw error
  }
}

export function getLanguages () {
  return Object.keys(languages).map((language) => {
    return languages[language]
  })
}

export function getLanguageCode () {
  return currentLanguageCode
}

export function setLanguageCode (arg) {
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`)
  }

  localStorage.setItem('language', arg)
}

export function i18nExists (key) {
  if (!getLanguage()) {
    return false
  }

  const message = _get(getLanguage().dictionary, key)
  return Boolean(message)
}

export function i18n (key, ...args) {
  if (!getLanguage()) {
    return key
  }

  const message = _get(getLanguage().dictionary, key)

  if (!message) {
    return key
  }

  return format(message, args)
}

export function i18nHtml (key, ...args) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: i18n(key, ...args),
      }}
    />
  )
}
