import list from '../../modules/subcategory/list/subcategoryListReducers'
import form from '../../modules/subcategory/form/subcategoryFormReducers'
import view from '../../modules/subcategory/view/subcategoryViewReducers'
import destroy from '../../modules/subcategory/destroy/subcategoryDestroyReducers'
import importerReducer from '../../modules/subcategory/importer/subcategoryImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})
