import SubsubcategoryService from '../../../modules/subcategory/subcategoryService'
import Errors from '../../../modules/shared/error/errors'
import { push } from 'redux-first-history'

const prefix = 'SUBCATEGORY_VIEW'

const subcategoryViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: subcategoryViewActions.FIND_STARTED,
      })

      const record = await SubsubcategoryService.find(id)

      dispatch({
        type: subcategoryViewActions.FIND_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: subcategoryViewActions.FIND_ERROR,
      })

      dispatch(push('/subcategory'))
    }
  },
}

export default subcategoryViewActions
